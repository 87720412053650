<template>
  <div class="page-about">
    <AppHeader />
    <div class="page" :class="mainCSS">
      <div class="container">
        <div class="columns">
          <div class="column is-half left-column">
            <h1>About<br />Seedback</h1>

            <a
              class="icon-arrow-back is-white"
              @click="back()"
              aria-label="Back"
              v-if="mode != 'faq'"
            ></a>

            <div class="about-group">
              <div class="content" v-html="$root.$options.about"></div>

              <div class="version">Version {{ version }}</div>

              <div class="button-wrap">
                <a class="button" @click="changeMode('feedback')">
                  <span class="icon-edit"></span>
                  Send feedback
                </a>

                <a class="button" @click="changeMode('privacy')">
                  <span class="icon-shiekd"></span>
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
          <div class="column is-half right-column">
            <section class="faq-section" v-if="mode == 'faq'">
              <h1>FAQ'S</h1>
              <el-collapse>
                <el-collapse-item
                  v-for="(item, index) in $root.$options.faq"
                  :key="index"
                  :title="item.question"
                  :name="index"
                >
                  <div v-html="item.answer"></div>
                </el-collapse-item>
              </el-collapse>
            </section>

            <section class="privacy-section" v-if="mode == 'privacy'">
              <h1>Privacy<br />Policy</h1>
              <div
                class="content"
                v-html="$root.$options.tokens['PRIVACY-POLICY']"
              ></div>
            </section>

            <section class="send-feedback-section" v-if="mode == 'feedback'">
              <div class="form-active" v-if="!feedbackOk">
                <h1>Send<br />Feedback</h1>
                <div class="content">
                  <p>
                    Something went wrong? Have some suggestions? Send feedback
                    to Seedback!
                  </p>
                </div>
                <form @submit.prevent="sendFeedback" class="feedback-form">
                  <div class="field">
                    <label class="label has-text-white">Topic</label>
                    <div class="control">
                      <input class="input" type="text" v-model="topic" />
                    </div>
                  </div>
                  <div class="field">
                    <label class="label has-text-white">Your menssage:</label>
                    <div class="control">
                      <textarea class="textarea" v-model="message"></textarea>
                    </div>
                  </div>
                  <div class="field is-grouped is-grouped-right">
                    <p class="control">
                      <button
                        type="submit"
                        class="button-send button is-white is-outlined"
                      >
                        Send
                      </button>
                    </p>
                  </div>
                  <br />
                  <div class="has-text-danger">{{ error }}</div>
                </form>
              </div>
              <div class="thank-you" v-else>
                <div>Thank you for your feedback</div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import { version } from "../../package";

export default {
  name: "About",
  components: {
    AppHeader,
    AppFooter
  },
  data() {
    return {
      mode: "faq" /* faq, feedback, privacy*/,
      topic: "",
      message: "",
      error: "",
      feedbackOk: false,
      version: version
    };
  },
  computed: {
    mainCSS() {
      if (this.mode == "faq") return "";
      else return "is-collapsed";
    }
  },
  methods: {
    changeMode(newMode) {
      this.mode = newMode;
      this.feedbackOk = false;
    },
    back() {
      this.mode = "faq";
    },
    sendFeedback() {
      if (this.topic.trim() != "" && this.message.trim() != "") {
        this.axiosRequest("post", "send-feedback", {
          topic: this.topic,
          message: this.message
        })
          .then((response) => {
            console.log(":", response.data);
            //there is no UI for the feedback...
            this.feedbackOk = true;
          })
          .catch((error) => {
            console.error(error);
            this.error =
              "Error while trying to send the message, please try later.";
          });
      } else {
        this.error = "Please provide a topic and a message.";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.page-about {
  background: $primary-color;
  min-height: 100%;
  position: relative;

  .page {
    color: #ffffff;
    padding: 100px 0 200px 0;
    text-align: left;

    @include ay-mobile {
      padding-top: 60px;
    }

    &.is-collapsed {
      .about-group {
        display: none;
      }

      .left-column {
        width: 200px;

        h1 {
          opacity: 0.6;
        }
      }

      .right-column {
        border-left: none;
      }
    }
  }

  .icon-arrow-back {
    display: block;
    position: absolute;
    left: 180px;
    top: 15px;
    transform: rotate(180deg) scale(0.8);
  }

  h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 1em;
  }

  .right-column {
    border-left: solid 1px #8adbcf;
    padding-left: 40px;

    @include ay-mobile {
      border-left: none;
      padding-left: 0.7rem;
    }
  }

  .version {
    background: #44c5b2;
    padding: 3px 6px;
    display: inline-block;
  }

  .button-wrap {
    .button {
      color: #ffffff;
      background: transparent;
      border: none;
      padding: 0 !important;
      display: block !important;
      width: 190px;
      text-align: left;
      text-decoration: underline;
      height: auto;
      margin: 9px 0;
      font-size: 18px;
      font-weight: $weight-regular;

      span {
        margin-right: 8px;
      }
    }
  }

  .send-feedback-section {
    .content {
      margin-top: 15px;
      font-size: 20px;
    }
    .button-send {
      min-width: 100px;
    }
    .thank-you {
      text-align: center;
      font-weight: bold;
      font-size: 28px;
      padding: 120px 0;
    }
  }
}
</style>
